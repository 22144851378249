import cn from 'classnames'

import './Title.scss'

export type ITitle = {
  children: React.ReactNode
  align?:
    | 'start'
    | 'center'
    | 'inherit'
    | 'initial'
    | 'unset'
    | 'end'
    | 'justify'
    | 'left'
    | 'right'
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p'
  color?:
    | 'default'
    | 'main'
    | 'secondary'
    | 'inherit'
    | 'white'
    | 'black-400'
    | 'black-300'
    | 'black-200'
    | 'purple-100'
    | 'purple-200'
    | 'purple-400'
    | 'purple-500'
    | 'purple-600'
    | 'green-500'
  size?: 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl'
  weight?: 'm' | 'l' | 'xl'
  className?: string
  ellipsis?: boolean
  ellipsisRows?: 'two' | 'three' | 'fourth'
  childrenTagColor?: null | 'main' | 'green-500' | 'green-600'
  variant?: 'expanded' | 'narrow' | 'default'
}

const Title = ({
  children,
  className,
  tag = 'h2',
  color = 'default',
  size = 'l',
  weight = 'l',
  align = 'inherit',
  ellipsis = false,
  ellipsisRows,
  childrenTagColor = null,
  variant = 'default',
}: ITitle) => {
  const Tag = tag

  return (
    <Tag
      className={`${cn(
        'title',
        `title--color-${color}`,
        `title--variant-${variant}`,
        `title--size-${size}`,
        `title--align-${align}`,
        `title--weight-${weight}`,
        {
          [`${className}`]: !!className,
          [`title--ellipsis-rows-${ellipsisRows}`]: !!ellipsisRows,
          [`title--children-tag-color-${childrenTagColor}`]: !!childrenTagColor,
          'title--is-ellipsis': ellipsis && !ellipsisRows,
        },
      )}`}
    >
      {children}
    </Tag>
  )
}

export default Title
