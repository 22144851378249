import variables from '@/app/src/app/scss/abstracts/export.module.scss'

const { breakpointSm, breakpointMd, breakpointLg, breakpointXl, breakpointXXl, breakpointXXXl } =
  variables

export const breakpoints = {
  sm: parseInt(breakpointSm),
  md: parseInt(breakpointMd),
  lg: parseInt(breakpointLg),
  xl: parseInt(breakpointXl),
  '2xl': parseInt(breakpointXXl),
  '3xl': parseInt(breakpointXXXl),
} as const
