'use client'

import cn from 'classnames'

import { Swiper, SwiperSlide } from 'swiper/react'

import { Icon } from '@/app/src/shared/ui/Icon'
import LogoClutch from '@/app/src/entities/review/images/clutch.svg?url'
import Image from 'next/image'
import { Mousewheel, Keyboard } from 'swiper/modules'

import { Review } from '@/app/src/entities/review/ui/Review'
import { getReviewCountWithTitle } from '@/app/src/entities/review/lib'

import './Carousel.scss'
import { IReview } from '@/app/src/entities/review/model'

export type IProps = {
  className?: string
  reviews: IReview[]
  reviewsAmount: number | null
}

const Carousel = ({ className, reviews, reviewsAmount }: IProps) => {
  const reviewsText = getReviewCountWithTitle(reviewsAmount)

  return (
    <div
      className={cn('carousel', {
        [`${className}`]: !!className,
      })}
    >
      <Swiper
        modules={[Mousewheel, Keyboard]}
        keyboard={{ enabled: true }}
        mousewheel={{ forceToAxis: true }}
        slidesPerView="auto"
      >
        <SwiperSlide>
          <a
            href="https://clutch.co/profile/devfamily?utm_source=widget&amp;utm_medium=2&amp;utm_campaign=widget&amp;utm_content=logo#summary"
            target="_blank"
            className="carousel__clutch"
          >
            <div className="carousel__clutch-box">
              <div className="carousel__clutch-title">reviewed on</div>

              <Image
                className="carousel__clutch-logo"
                width="168"
                height="48"
                src={LogoClutch}
                alt="Clutch"
              />

              <div className="carousel__clutch-stars">
                {Array(5)
                  .fill('')
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  .map((_, key) => (
                    <div key={key} className="carousel__clutch-star-icon">
                      <Icon width="40" height="40" name="sprite/star" />
                    </div>
                  ))}
              </div>

              <div className="carousel__clutch-reviews">{reviewsText}</div>
            </div>
          </a>
        </SwiperSlide>

        {reviews.map((item) => (
          <SwiperSlide key={item?.id}>
            <Review review={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Carousel
