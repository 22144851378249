import cn from 'classnames'
import type { SVGProps } from 'react'

import { AnyIconName, getIconMeta } from './lib'

interface IconProps extends SVGProps<SVGSVGElement> {
  name: AnyIconName
}

export function Icon({ name, className, ...props }: IconProps) {
  const { viewBox, filePath, iconName, axis } = getIconMeta(name)

  return (
    <svg
      className={cn('icon', className)}
      viewBox={viewBox}
      data-axis={axis}
      focusable="false"
      aria-hidden
      {...props}
    >
      <use href={`/sprites/${filePath}#${iconName}`} />
    </svg>
  )
}
