export const HOST = process.env.NEXT_PUBLIC_HOST
export const NODE_ENV = process.env.NODE_ENV
export const STORYBOOK = process.env.STORYBOOK ?? ''

export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const API_URL_NEW = process.env.NEXT_PUBLIC_API_URL_NEW

export const isDevEnv = NODE_ENV === 'development'
export const isProdEnv = NODE_ENV === 'production'
export const isStorybook = STORYBOOK || false
