import type { ImageProps } from 'next/image'
import {
  IArtDirection,
  IBreakpointsKeys,
  IArtDirectionGenerated,
  IImageLoader,
  IImageSrc,
} from '../model'

import { getImage } from './getImage'
import { isEmptyObject } from '@/app/src/shared/lib/isEmptyObject'

const getPreviousSrc = (
  key: IBreakpointsKeys,
  breakpointKeys: IBreakpointsKeys[],
  artDirection: IArtDirection,
): IImageSrc | null => {
  const index = breakpointKeys.indexOf(key)

  if (index <= 0) return null

  for (let i = breakpointKeys.indexOf(key) - 1; i >= 0; i--) {
    const previousKey = breakpointKeys[i]
    const previousSrc = artDirection[previousKey]?.src

    if (previousSrc) return previousSrc
  }

  return null
}

export const generateArtDirection = (
  defaultImage: ImageProps,
  loader: IImageLoader,
  artDirection?: IArtDirection,
): IArtDirectionGenerated => {
  const props = {} as Exclude<IArtDirectionGenerated, null>

  const {
    alt,
    src: fallbackSrc,
    quality: fallbackQuality,
    priority: fallbackPriority,
  } = defaultImage

  if (!artDirection) return null
  if (isEmptyObject(artDirection)) return null

  const breakpointKeys = Object.keys(artDirection) as IBreakpointsKeys[]

  for (const key of breakpointKeys) {
    const currentImage = artDirection[key]

    if (currentImage) {
      const { src, sizes, size, ...other } = currentImage

      const rest = {
        ...other,
        alt,
        quality: other.quality || fallbackQuality,
        priority: other.priority ?? fallbackPriority,
      }

      if (src) {
        const imageProps = getImage({ src, ...rest }, loader, sizes, size)

        props[key] = imageProps
      } else {
        const previousSrc = getPreviousSrc(key, breakpointKeys, artDirection)

        if (previousSrc) {
          const imageProps = getImage({ src: previousSrc, ...rest }, loader, sizes, size)

          props[key] = imageProps
        } else {
          if (fallbackSrc) {
            const imageProps = getImage({ src: fallbackSrc, ...rest }, loader, sizes, size)

            props[key] = imageProps
          } else {
            props[key] = null
          }
        }
      }
    } else {
      props[key] = null
    }
  }

  return props
}
