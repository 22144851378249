import { ImageProps } from 'next/dist/shared/lib/get-img-props'
import { IArtDirection, IImageLoader, IImageSize, IImageSizes, INextImageGenerated } from '../model'
import { getImage } from './getImage'
import { generateArtDirection } from './generateArtDirection'
import { generateSourceTags } from './generateSourceTags'
import { generateStyles } from './generateStyles'

const generateNextImageConfig = (
  id: string,
  defaultProps: ImageProps,
  loader: IImageLoader,
  sizes?: IImageSizes,
  size?: IImageSize,
  artDirection?: IArtDirection,
): INextImageGenerated => {
  const styles = generateStyles(
    id,
    defaultProps.width as number,
    defaultProps.height as number,
    sizes,
    size,
    artDirection,
  )

  const defaultImageProps = getImage(defaultProps, loader, sizes, size)
  const artDirectionProps = generateArtDirection(defaultProps, loader, artDirection)

  const sourceTags = generateSourceTags(artDirectionProps)

  return {
    styles,
    sourceTags,
    defaultImageProps,
  }
}

export default generateNextImageConfig
