import { articleApi } from '@/app/src/entities/article'
import { IArticle } from '@/app/src/entities/article/model'
import { Mousewheel, Keyboard } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'

export const articlesCarouselModel = {
  getCarouselConfig: (slidesPerView: number = 4) => {
    const swiperConfig: SwiperOptions = {
      modules: [Mousewheel, Keyboard],
      keyboard: { enabled: true },
      mousewheel: { forceToAxis: true },
      slidesPerView: 'auto',
      spaceBetween: 0,
      breakpoints: {
        1440: {
          slidesPerGroup: 1,
          slidesPerView,
          spaceBetween: 0,
        },
        1024: {
          slidesPerGroup: 1,
          slidesPerView: 3,
          spaceBetween: 0,
        },
        700: {
          slidesPerGroup: 1,
          slidesPerView: 2,
          spaceBetween: 0,
        },
        375: {
          slidesPerView: 'auto',
          spaceBetween: 0,
        },
      },
    }

    return swiperConfig
  },
}

type IUseArticlesData = {
  articlesFromParent?: IArticle[]
  tag?: string | null
  category?: string | null
}

const getArticlesByTagOrCategoryOrAll = async (tag?: string | null, category?: string | null) => {
  let articlesData

  if (tag) {
    articlesData = await articleApi.getArticles({
      filter: {
        tag,
      },
    })

    return articlesData.data
  }

  if (category) {
    articlesData = await articleApi.getArticles({
      filter: {
        category,
      },
    })

    return articlesData.data
  }

  articlesData = await articleApi.getArticles({})

  return articlesData.data
}

export const useArticlesModelData = async ({
  articlesFromParent,
  tag,
  category,
}: IUseArticlesData) => {
  let articles = articlesFromParent

  if (!articlesFromParent) {
    articles = await getArticlesByTagOrCategoryOrAll(tag, category)
  }

  return {
    articles,
  }
}
