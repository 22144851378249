import { isDevEnv, isStorybook } from '@/app/src/app/config/env'
import { IImageLoader, IImageSrc } from '../model'
import { ImageLoaderProps } from 'next/image'
import { checkAbsoluteUrl } from '@/app/src/shared/lib/checkAbsoluteUrl'

const defaultLoader = undefined

const imgProxyLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `/imgproxy?url=${encodeURIComponent(src)}&width=${width}&quality=${quality}`
}

export const getImageLoader = (loader: IImageLoader, src: IImageSrc | null) => {
  if (isStorybook || loader === 'next') return defaultLoader

  if (loader === 'imgproxy') return imgProxyLoader

  if (isDevEnv) {
    return checkAbsoluteUrl(src as string) ? imgProxyLoader : defaultLoader
  }

  return imgProxyLoader
}
