import { useFormState } from 'react-dom'
import { sendForm } from '@/app/src/features/send-application/api'
import { useEffect, useRef, useState } from 'react'
import JSConfetti from 'js-confetti'
import { type SwiperClass } from 'swiper/react'
import { ImperativeHandlers } from '@/app/src/features/send-application/ui/Form/Form'
import { useRouter } from 'next/navigation'
import { analyticsApi } from '@/app/src/entities/analytics'
import type { Route } from 'next'

const BACK_SLIDE_TIMEOUT = 6000
const CONFETI_DELAY = 400

type IProps = {
  redirectToAfterSuccess?: null | string
}

export const useFeedbackForm = ({ redirectToAfterSuccess = null }: IProps = {}) => {
  const [state, formAction] = useFormState(sendForm, {
    isSuccess: false,
    isError: false,
    counter: 0,
    errors: {},
  })

  const [isSendedForm, setSendedForm] = useState(false)
  const timeoutIdBackForm = useRef<ReturnType<typeof setTimeout>>()
  const timeoutIdConfeti = useRef<ReturnType<typeof setTimeout>>()

  const confetiRef = useRef<JSConfetti>()
  const swiperRef = useRef<SwiperClass>()
  const formRef = useRef<ImperativeHandlers>(null)
  const router = useRouter()

  useEffect(() => {
    if (state.isSuccess) {
      setSendedForm(true)
      resetForm()

      analyticsApi.sendFormSuccess()
    }

    if (state.isError) {
      analyticsApi.sendFormError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.counter, state.isSuccess])

  useEffect(() => {
    if (isSendedForm) {
      if (redirectToAfterSuccess) {
        return router.push(redirectToAfterSuccess as Route)
      }

      confetiRef.current = new JSConfetti()

      nextSlide()
      startConfetiTimeout()
      startPrevSlideTimeout()
    }

    return () => clearEffects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendedForm])

  const prevSlide = () => {
    if (swiperRef.current) swiperRef.current.slidePrev()
  }

  const nextSlide = () => {
    if (swiperRef.current) swiperRef.current.slideNext()
  }

  const startPrevSlideTimeout = () => {
    timeoutIdBackForm.current = setTimeout(() => {
      prevSlide()
      setSendedForm(false)
    }, BACK_SLIDE_TIMEOUT)
  }

  const startConfetiTimeout = () => {
    timeoutIdConfeti.current = setTimeout(() => {
      if (confetiRef.current) {
        confetiRef.current.addConfetti()
      }
    }, CONFETI_DELAY)
  }

  const handleBackClick = () => {
    clearTimeout(timeoutIdBackForm.current)
    prevSlide()
    setSendedForm(false)
  }

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const clearEffects = () => {
    if (confetiRef.current) {
      confetiRef.current.clearCanvas()
      confetiRef.current.destroyCanvas()
    }

    clearTimeout(timeoutIdConfeti.current)
    clearTimeout(timeoutIdBackForm.current)
  }

  return {
    formAction,
    handleBackClick,
    errors: state.errors,
    swiperRef,
    formRef,
  }
}
