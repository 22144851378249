'use client'

import { Button } from '@/app/src/shared/ui/Button'
import { feedbackFormModel } from '@/app/src/widgets/Forms/FeedbackForm/model'

export const ButtonWorkWithUs = () => {
  return (
    <div className="intro__cell-button">
      <Button
        variant="main-outline"
        onClick={() => feedbackFormModel.srollToMe()}
        hasRightNeighbour
        hoverWithInRightNeighbour
      >
        Work with us
      </Button>
    </div>
  )
}
