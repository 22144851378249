'use client'

import cn from 'classnames'

import Marquee from 'react-fast-marquee'

import { badgeApi } from '@/app/src/entities/award'
import { NextImage } from '@/app/src/shared/ui/NextImage'

import './Awards.scss'

export type IProps = {
  className?: string
}

const Awards = ({ className }: IProps) => {
  const awards = badgeApi.getBadges()

  return (
    <section
      className={cn('awards', {
        [`${className}`]: !!className,
      })}
    >
      <Marquee autoFill>
        <div className="awards__grid">
          {awards.map(({ id, title, image }) => (
            <div key={id} className="awards__cell">
              <div className="awards__image">
                <NextImage disableLoadingState width={70} height={70} src={image} alt={title} />
              </div>

              <div className="awards__title">{title}</div>
            </div>
          ))}
        </div>
      </Marquee>
    </section>
  )
}

export default Awards
