'use client'

import { useCollapse } from 'react-collapsed'
import Link from 'next/link'
import Image from 'next/image'
import { Icon } from '@/app/src/shared/ui/Icon'
import { Title } from '@/app/src/shared/ui/Title'
import cn from 'classnames'
import variables from '@/app/src/app/scss/abstracts/export.module.scss'
import { useEffect } from 'react'
import { Button } from '@/app/src/shared/ui/Button'
import { expertice } from '../../config'
import type { Route } from 'next'

const { breakpointLg } = variables

export const ExperticeItem = ({
  list,
  href,
  title,
  width,
  height,
  image,
  id,
}: (typeof expertice)[number] & { id: number }) => {
  const { getCollapseProps, isExpanded, setExpanded } = useCollapse()

  const handleLinkClick = (e: React.SyntheticEvent) => {
    if (window.innerWidth < parseInt(breakpointLg)) {
      e.preventDefault()

      setExpanded(!isExpanded)
    }
  }

  useEffect(() => {
    if (id === 0) {
      // setExpanded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={cn('expertice__item', { 'expertice__item--submenu-opened': isExpanded })}>
      <div className="expertice__link-wrap">
        <Link onClick={handleLinkClick} href={href as Route} className="expertice__link">
          <div className="expertice__image">
            <Image src={image} width={width} height={height} alt={title} />
          </div>

          <div className="expertice__item-title">
            <Title color="inherit" size="l" tag="span">
              {title}
            </Title>
          </div>

          <div className="expertice__item-icon">
            <Icon name="arrows/arrow-outside" />
          </div>

          <div className={cn('expertice__toggler')}>
            <div className="expertice__toggler-btn">
              <Icon width="22" height="22" name="arrows/chevron-down" />
            </div>
          </div>
        </Link>
      </div>

      <div className="expertice__list-wrap" {...getCollapseProps()}>
        <ul className="expertice__list">
          {list.map(({ title }, key) => (
            <li className="expertice__list-item" key={key}>
              {title}
            </li>
          ))}
        </ul>

        <div className="expertice__button">
          <Button size="m" hasIconRight href={href as Route} fullWidth variant="white-outline">
            Explore {title}
          </Button>
        </div>
      </div>
    </div>
  )
}
