export const awards = [
  {
    id: 110,
    title: 'Top Ecommerce Web Development Companies 2024',
    year: 2024,
    image: require('../images/badge-23.png').default.src,
    href: 'https://www.mobileappdaily.com/directory/web-development-companies/ecommerce',
  },
  {
    id: 105,
    title: 'Top Web Development Companies in Raleigh!',
    year: 2024,
    image: require('../images/badge-22.png').default.src,
    href: 'https://www.mobileappdaily.com/directory/web-development-companies/us/raleigh',
  },
  {
    id: 100,
    title: 'Top Retail Software Development Companies 2024',
    year: 2024,
    image: require('../images/badge-21.png').default.src,
    href: 'https://www.mobileappdaily.com/directory/software-development-companies/retail',
  },
  {
    id: 95,
    title: 'Top Web Development Companies in Canada 2024',
    year: 2024,
    image: require('../images/badge-20.png').default.src,
    href: 'https://www.mobileappdaily.com/directory/web-development-companies/ca',
  },
  {
    id: 90,
    title: 'Top 100+ Android App Developers in 2024',
    year: 2024,
    image: require('../images/badge-19.svg?url').default.src,
    href: 'https://techreviewer.co/top-android-app-development-companies',
  },
  {
    id: 85,
    title: 'Top Mobile App Development Company',
    year: 2024,
    image: require('../images/badge-18.png').default.src,
  },
  {
    id: 1,
    title: 'Top Software Developers For Startups',
    year: 2024,
    image: require('../images/badge-1.svg?url').default.src,
  },
  {
    id: 5,
    title: 'Top Web Design Agency',
    year: 2024,
    image: require('../images/badge-2.png').default.src,
  },
  {
    id: 10,
    title: 'Top Company',
    year: 2023,
    image: require('../images/badge-3.png').default.src,
  },
  {
    id: 15,
    title: 'Top App Development Company',
    year: 2023,
    image: require('../images/badge-4.png').default.src,
  },
  {
    id: 20,
    title: 'Top Web Developers',
    year: 2023,
    image: require('../images/badge-5.png').default.src,
  },
  {
    id: 25,
    title: 'Top App Development Company',
    year: 2023,
    image: require('../images/badge-6.png').default.src,
  },
  {
    id: 30,
    title: 'Most Reviewed App Development Company',
    year: 2023,
    image: require('../images/badge-7.png').default.src,
  },
  {
    id: 35,
    title: 'Top Software Developers For Startups',
    year: 2023,
    image: require('../images/badge-8.svg?url').default.src,
  },
  {
    id: 40,
    title: 'Trusted on Tech Behemoths',
    year: 2022,
    image: require('../images/badge-9.svg?url').default.src,
  },
  {
    id: 45,
    title: 'Top Mobile App Development Company',
    year: 2022,
    image: require('../images/badge-10.png').default.src,
  },
  {
    id: 50,
    title: 'Top Web Developers',
    year: 2022,
    image: require('../images/badge-11.svg?url').default.src,
  },
  {
    id: 55,
    title: 'Top B2B Companies',
    year: 2021,
    image: require('../images/badge-12.png').default.src,
  },
  {
    id: 60,
    title: 'Top B2B Companies',
    year: 2022,
    image: require('../images/badge-13.png').default.src,
  },
  {
    id: 65,
    title: 'Top Developers',
    year: 2021,
    image: require('../images/badge-14.png').default.src,
  },
  {
    id: 70,
    title: 'Top Mobile App',
    year: 2021,
    image: require('../images/badge-15.png').default.src,
  },
  {
    id: 75,
    title: 'Top Development Companies',
    year: 2022,
    image: require('../images/badge-16.svg?url').default.src,
  },
  {
    id: 80,
    title: 'Top Web Developers Companies',
    year: 2021,
    image: require('../images/badge-17.png').default.src,
  },
]
