/* eslint-disable jsx-a11y/alt-text */
import type { ImageProps } from 'next/image'
import {
  IArtDirection,
  IImageSizeConditional,
  IImageLoader,
  IImageQuality,
  IImageSize,
  IImageSizes,
  IImageSrc,
} from './model'
import cn from 'classnames'
import generateNextImageConfig from './lib'
import { CSSProperties, useId } from 'react'
import { NextImageClient } from './NextImage.client'

import './NextImage.scss'

// Это серверный компонент, не делайте его клиентским. Он станет клиентским сам при необходимости

export type INextImageProps = {
  src: IImageSrc | null | undefined
  width: number
  height: number
  alt: string

  priority?: boolean

  quality?: IImageQuality
  size?: IImageSize
  sizes?: IImageSizes
  loader?: IImageLoader
  artDirection?: IArtDirection
  disableLoadingState?: boolean
  style?: CSSProperties // Этого здесь не должно быть, для поддержания работы более старого кода
} & IImageSizeConditional

const NextImage = ({
  quality = 75,
  width,
  height,
  alt,
  src,
  priority = false,
  loader = 'auto',
  artDirection,
  size,
  sizes,
  disableLoadingState = false,
  style,
}: INextImageProps) => {
  const id = useId()

  const defaultProps: ImageProps = {
    src: src || '',
    quality,
    alt,
    priority,
    width,
    height,
  }

  const { defaultImageProps, sourceTags, styles } = generateNextImageConfig(
    id,
    defaultProps,
    loader,
    sizes,
    size,
    artDirection,
  )

  const renderImage = <NextImageClient {...defaultImageProps} />

  return (
    <div
      style={style}
      className={cn('next-image', { 'next-image--disable-loading-state': disableLoadingState })}
    >
      <style dangerouslySetInnerHTML={{ __html: styles }} />

      <div data-id={id} className="next-image__inner">
        {sourceTags.length ? (
          <picture>
            {sourceTags}
            {renderImage}
          </picture>
        ) : (
          renderImage
        )}
      </div>
    </div>
  )
}

export default NextImage
