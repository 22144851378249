import './SubmitButton.scss'
import { Button } from '@/app/src/shared/ui/Button'
import { useFormStatus } from 'react-dom'

const SubmitButton = () => {
  const { pending } = useFormStatus()

  return (
    <Button disabled={pending} type="submit">
      Get in touch
    </Button>
  )
}

export default SubmitButton
