import cn from 'classnames'

import './Text.scss'

export type IProps = {
  children: React.ReactNode
  align?:
    | 'start'
    | 'center'
    | 'inherit'
    | 'initial'
    | 'unset'
    | 'end'
    | 'justify'
    | 'left'
    | 'right'
  tag?: 'p' | 'span' | 'div'
  color?:
    | 'default'
    | 'main'
    | 'secondary'
    | 'dark-gray-300'
    | 'dark-gray-400'
    | 'light-gray-400'
    | 'purple-100'
    | 'purple-200'
    | 'purple-400'
    | 'purple-500'
    | 'purple-600'
    | 'black-100'
    | 'black-200'
    | 'black-300'
    | 'black-400'
    | 'inherit'
    | 'white'
    | 'white-200'
  size?: '3xs' | '2xs' | 'xs' | 's' | 'm' | 'l'
  weight?: 's' | 'm' | 'l' | 'xl'
  className?: string
}

const Text = ({
  children,
  className,
  tag = 'p',
  color = 'default',
  size = 'l',
  align = 'inherit',
  weight = 's',
}: IProps) => {
  const Tag = tag

  return (
    <Tag
      className={`${cn(
        'text',
        `text--color-${color}`,
        `text--size-${size}`,
        `text--align-${align}`,
        `text--weight-${weight}`,
        {
          [`${className}`]: !!className,
        },
      )}`}
    >
      {children}
    </Tag>
  )
}

export default Text
