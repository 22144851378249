import { isEmptyObject } from '@/app/src/shared/lib/isEmptyObject'
import {
  IArtDirection,
  IArtImage,
  IBreakpointsKeys,
  IImageMode,
  IImageSize,
  IImageSizes,
} from '../model'
import { breakpoints } from '../config'

export const getMode = (sizes?: IImageSizes, size?: IImageSize): IImageMode => {
  return size || (sizes && !isEmptyObject(sizes)) ? 'stretch' : 'shrink'
}

export const getStyles = (id: string, width: number, height: number, mode: IImageMode): string => {
  const _width = mode === 'shrink' ? `${width}px` : '100%'

  return `
    [data-id="${id}"] {
      aspect-ratio: ${width} / ${height};
      width: ${_width};
    }
  `
}

export const generateMediaQueriesStyles = (id: string, artDirection?: IArtDirection): string => {
  if (!artDirection) return ''
  if (isEmptyObject(artDirection)) return ''

  return Object.entries(artDirection)
    .map(([_key]) => {
      const key = _key as IBreakpointsKeys

      const image = artDirection[key] as IArtImage

      const width = image.width
      const height = image.height
      const size = image.size
      const sizes = image.sizes
      const mode = getMode(sizes, size)

      const maxWidth = breakpoints[key]

      return `
        @media (max-width: ${maxWidth}px) {
          ${getStyles(id, width, height, mode)}
        }
    `
    })
    .join('')
}

export const generateStyles = (
  id: string,
  width: number,
  height: number,
  sizes?: IImageSizes,
  size?: IImageSize,
  artDirection?: IArtDirection,
): string => {
  const mode = getMode(sizes, size)

  return `
    ${getStyles(id, width, height, mode)}
    ${artDirection ? generateMediaQueriesStyles(id, artDirection) : ''}
  `.replace(/\s/g, '')
}
