import { scrollToId } from '@/app/src/shared/lib/scrollTo'

export const feedbackFormModel = {
  srollToMe: () => {
    const firstField = document.getElementById('feedback-form')?.querySelector('textarea')

    scrollToId('feedback-form')
    firstField?.focus({ preventScroll: true })
  },
}
