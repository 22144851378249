import { breakpoints } from '../config'
import { IBreakpointsKeys, IImageSize, IImageSizes } from '../model'

export const generateSizes = (sizes?: IImageSizes, size?: IImageSize): string | undefined => {
  const sizesArray: string[] = []

  if (sizes) {
    const breakpointKeys = Object.keys(breakpoints) as IBreakpointsKeys[]

    for (const key of breakpointKeys) {
      const breakpoint = breakpoints[key]
      const size = sizes[key]

      if (size) {
        sizesArray.push(`(max-width: ${breakpoint}px) ${size}`)
      }
    }
  }

  if (size) {
    sizesArray.push(size)
  }

  return sizesArray.join(', ') || undefined
}
