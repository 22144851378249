'use client'

import cn from 'classnames'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { FieldText } from '@/app/src/shared/ui/FieldText'
import { forwardRef, useEffect, useImperativeHandle } from 'react'

import { IFormFeedbackBody } from '@/app/src/entities/application/model'

import './Form.scss'
import { IErrors } from '@/app/src/shared/model'

export type IProps = {
  className?: string
  variant?: 'dark' | 'light'
  children: React.ReactNode
  errors: IErrors

  getParentValues?: () => void
  onSubmit: (payload: IFormFeedbackBody) => void
}

const initialValues = {
  message: '',
  first_name: '',
  email: '',
  messenger: '',
}

export type ImperativeHandlers = {
  resetForm: () => void
}

const Form = forwardRef<ImperativeHandlers, IProps>(
  ({ className, children, onSubmit, getParentValues, errors = {}, variant = 'dark' }, ref) => {
    const { control, handleSubmit, reset, setFocus } = useForm<IFormFeedbackBody>({
      defaultValues: initialValues,
    })

    useImperativeHandle(ref, () => ({
      resetForm() {
        reset(initialValues)
      },
    }))

    useEffect(() => {
      const firstError = Object.keys(errors ?? {}).reduce<string>((field, a) => {
        return errors?.[field ?? ''] ? field : a
      }, '')

      if (firstError) {
        setFocus(firstError as keyof IFormFeedbackBody)
      }
    }, [errors, setFocus])

    const handler: SubmitHandler<IFormFeedbackBody> = (data) => {
      const dataAdditional = getParentValues ? getParentValues() : {}

      return onSubmit({ ...data, ...dataAdditional })
    }

    return (
      <form
        action={handleSubmit(handler) as unknown as (data: FormData) => void}
        className={cn('form', {
          [`${className}`]: !!className,
        })}
      >
        <div className="form__box">
          <div className="form__row">
            <div className="form__cell form__cell--full-width">
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <FieldText
                    variant={variant}
                    errors={errors?.['message']}
                    placeholder="Briefly describe your project"
                    title="Tell us about it:"
                    type="textarea"
                    {...field}
                  />
                )}
              />
            </div>
          </div>

          <div className="form__row">
            <div className="form__cell">
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <FieldText
                    variant={variant}
                    errors={errors?.['first_name']}
                    title="Contacts"
                    placeholder="Your name"
                    {...field}
                  />
                )}
              />
            </div>

            <div className="form__cell">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <FieldText
                    variant={variant}
                    errors={errors?.['email']}
                    title="Email"
                    placeholder="Your email"
                    type="email"
                    {...field}
                  />
                )}
              />
            </div>

            <div className="form__cell">
              <Controller
                name="messenger"
                control={control}
                render={({ field }) => (
                  <FieldText
                    variant={variant}
                    errors={errors?.['messenger']}
                    title="Phone"
                    placeholder="Your Phone Number"
                    type="tel"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form__control">{children}</div>
      </form>
    )
  },
)

Form.displayName = 'Form'

export default Form
