'use client'

import cn from 'classnames'
import './FieldText.scss'
import { forwardRef, useId } from 'react'

export type IProps = {
  className?: string
  title?: string
  placeholder?: string
  variant?: 'dark' | 'light'
  type?: 'text' | 'textarea' | 'email' | 'tel'
  value: string
  onChange: (value: string) => void
  errors?: string[]
  name?: string
}

export type Ref = HTMLInputElement & HTMLTextAreaElement

const FieldText = forwardRef<Ref, IProps>(
  (
    {
      className,
      title,
      placeholder = 'Place a placeholder here please...',
      type = 'text',
      value = '',
      variant = 'dark',
      errors = [],
      name,
      onChange,
    },
    ref,
  ) => {
    const fieldLabelId = useId()

    const parsedErrors = (() => {
      if (errors === null || errors.length === 0) return null

      return errors.join(', ')
    })()

    const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
      onChange(e.currentTarget.value)
    }

    const isTextarea = type === 'textarea'

    const inputComponent = (() => {
      return isTextarea ? (
        <textarea
          ref={ref}
          name={name}
          id={fieldLabelId}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleChange(e)}
        />
      ) : (
        <input
          ref={ref}
          name={name}
          id={fieldLabelId}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={(e) => handleChange(e)}
        />
      )
    })()

    return (
      <div
        className={cn('field-text', `field-text--variant-${variant}`, {
          [`${className}`]: !!className,
        })}
      >
        {title && (
          <div className={cn('field-text__title')}>
            <label htmlFor={fieldLabelId} className={cn('field-text__title-name')}>
              {title}
            </label>
          </div>
        )}

        <div className={cn('field-text__inner')}>{inputComponent}</div>

        {parsedErrors && <div className={cn('field-text__error')}>{parsedErrors}</div>}
      </div>
    )
  },
)

FieldText.displayName = 'FieldInput'

export default FieldText
