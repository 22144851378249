'use client'

import cn from 'classnames'
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { ImgProps } from 'next/dist/shared/lib/get-img-props'
import { useEffect, useRef, useState } from 'react'

export const NextImageClient = ({ src, loading, ...rest }: ImgProps) => {
  const [loaded, setLoaded] = useState(false)
  const ref = useRef<HTMLImageElement>(null)

  const onLoad = () => {
    setLoaded(true)
  }

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      setLoaded(true)
    }
  }, [])

  return (
    <img
      ref={ref}
      {...rest}
      loading={loading}
      src={src}
      onLoad={onLoad}
      className={cn('next-image__img', { 'next-image__img--is-loaded': loaded })}
    />
  )
}
