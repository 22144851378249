'use client'

import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { articlesCarouselModel } from '../../model'
import { ArticleCard } from '@/app/src/entities/article/ui/ArticleCard'
import { IArticle } from '@/app/src/entities/article/model'

import './ArticlesCarousel.scss'

export type IProps = {
  className?: string
  articles: IArticle[]
  slidesPreView?: number
}

const CarouselArticles = ({ className, articles, slidesPreView = 4 }: IProps) => {
  const swiperConfig = articlesCarouselModel.getCarouselConfig(slidesPreView)

  return (
    <div
      className={cn(
        'articles-carousel',
        {
          [`${className}`]: !!className,
        },
        `articles-carousel--per-view-${slidesPreView}`,
      )}
    >
      <Swiper {...swiperConfig}>
        {articles.map((article, key) => (
          <SwiperSlide key={key}>
            <div className="articles-carousel__cell">
              <ArticleCard article={article} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default CarouselArticles
