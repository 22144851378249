import { generateSizes } from './generateSizes'
import { IImageLoader, IImageSize, IImageSizes, IImageSrc } from '../model'
import { getImageLoader } from './getImageLoader'
import type { ImageProps } from 'next/image'
import { getImageProps } from 'next/image'
import { isStorybook } from '@/app/src/app/config/env'

const checkSvg = (src: IImageSrc): boolean => {
  let url: string

  if (typeof src === 'string') {
    url = src
  } else if ('default' in src) {
    url = src.default.src
  } else {
    url = src.src
  }

  const extension = /[.]/.exec(url) ? /[^.]+$/.exec(url) : undefined

  return extension?.[0] === 'svg'
}

export const getImage = (
  { src, ...rest }: ImageProps,
  loader: IImageLoader,
  sizes?: IImageSizes,
  size?: IImageSize,
) => {
  const { props: imageProps } = getImageProps({
    loader: getImageLoader(loader, src),
    sizes: generateSizes(sizes, size),
    src,
    ...rest,
    unoptimized: !!isStorybook || checkSvg(src), // Не работает в сторибуке, т.к. там нет сервера и отключаем оптимизацию, если это svg
  })

  return imageProps
}
