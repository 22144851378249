import cn from 'classnames'
import { Title } from '@/app/src/shared/ui/Title'
import Link from 'next/link'
import { formatDate } from '@/app/src/shared/lib/formatDate'
import { IArticle, IArticleTag } from '../../model'

import './ArticleCard.scss'
import { INextLink } from '@/app/src/shared/model'
import { NextImage } from '@/app/src/shared/ui/NextImage'

type Props = {
  className?: string
  article: IArticle
}

const ArticleCard = ({ className, article }: Props) => {
  const isExternal = article?.isExternal
  const date = formatDate(article?.publication_date ?? '')
  const href = (isExternal ? article.slug : `/blog/article/${article?.slug}`) as INextLink

  const classNameAuthor = 'article-card__author'
  const authorEl = (
    <>
      {!isExternal ? (
        <Link
          className={classNameAuthor}
          href={`/blog/authors/${article?.author?.slug}#layout-body`}
        >
          {article?.author?.nameWithPosition}
        </Link>
      ) : (
        <cite className={classNameAuthor}>{article.author?.nameWithPosition}</cite>
      )}
    </>
  )

  return (
    <article
      className={cn('article-card', {
        [`${className}`]: !!className,
      })}
    >
      <div className="article-card__image-box">
        <NextImage
          width={405}
          height={240}
          src={article?.preview?.desktop?.webp_x2}
          alt={article?.title ?? ''}
          size="33vw"
          sizes={{
            '2xl': '24vw',
            xl: '30vw',
            lg: '50vw',
            md: '46vw',
            sm: '94vw',
          }}
        />
      </div>

      <div className="article-card__text-box">
        <div className="article-card__title">
          <Title color="inherit" size="m" tag="h3">
            {article?.title}
          </Title>
        </div>

        <div className="article-card__author-wrap">{authorEl}</div>

        <div className="article-card__bottom-box">
          <ul className="article-card__tags">
            {article?.tags.map((tag, idx) => <ArticleTag key={idx} tag={tag} />)}
          </ul>

          <div className="article-card__date-reading">
            <time className="article-card__date" dateTime={article?.publication_date ?? undefined}>
              {date}
            </time>

            <div className="article-card__reading">{article?.reading_time}</div>
          </div>
        </div>
      </div>

      <Link
        href={href ?? '/'}
        target={!isExternal ? '_self' : '_blank'}
        className="article-card__link"
      />
    </article>
  )
}

type PropsArticleTag = {
  tag: IArticleTag
}

const ArticleTag = ({ tag }: PropsArticleTag) => {
  return (
    <li className="article-card__tag">
      {!tag?.isExternal ? (
        <Link href={`/blog/tags/${tag?.slug}#layout-body`}>{tag?.title}</Link>
      ) : (
        <span>{tag?.title}</span>
      )}
    </li>
  )
}

export default ArticleCard
